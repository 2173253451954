import {
  BFButtonComponent, BFDialogResponse,
  BFNotificationService,
  BFOverlayComponent
} from "../../../../libs/material";
import {Component, OnDestroy} from "@angular/core";
import {NgFor, NgIf} from "@angular/common";
import {EllipsisMiddlePipe} from "shared/pipes/ellipsisMiddle.pipe";
import {LandingPageModel} from "shared/models/landingPage.model";
import {PublishProgressResponse, PublishService} from "shared/services/publish.service";
import {LandingPageService} from "shared/services/landingPage.service";
import {TranslationModel} from "shared/models/translation.model";
import {PublishState} from "shared/enums/publishState.enum";
import {UIModule} from "@bannerflow/ui";

@Component({
    styleUrls: ['./publishOverlay.component.scss'],
    selector: 'publishOverlay',
    templateUrl: './publishOverlay.component.html',
    standalone: true,
  imports: [BFOverlayComponent, NgIf, BFButtonComponent, NgFor, EllipsisMiddlePipe, UIModule]
})
export class PublishOverlayComponent implements OnDestroy {

    private landingPage: LandingPageModel;
    private callback: Function;
    public loading: boolean;
    private cnames: any;
    private allSelected = false;
    private type: 'publish' | 'unpublish' = 'publish';
    private numberOfAffectedVersions: number;
    private publishingText: string;
    private publishingErrorText: string;

    constructor(private publishService: PublishService,
        private landingPageService: LandingPageService,
        private notificationService: BFNotificationService) { }

    // todo: dont pass in untyped cnames. maybe just bake it into the publish service and dont even know about it here
    public initiate(landingPage: LandingPageModel, cnames: any, selectedTranslations: TranslationModel[], type: 'publish' | 'unpublish' = 'publish'): Promise<BFDialogResponse<Date>> {
        this.landingPage = landingPage;
        this.cnames = cnames;
        this.emptySelection();
        this.type = type;
        const originalTranslation = landingPage.originalTranslation;
        for (const st of selectedTranslations) {
            if (originalTranslation.id === st.id) {
                (originalTranslation as any).selected = true;
            }
            const t = this.landingPage.translations.find(t => t.id === st.id);
            if (t) {
                (t as any).selected = true;
            }
        }

        var promise = new Promise<BFDialogResponse<Date>>((resolve) => {
            this.callback = resolve;
        });

        return promise;
    }

    get numberOfSelectedVersions(): number {
        let numberOfSelected = this.landingPage.translations.reduce((sum, translation) => {
            if ((translation as any).selected) {
                return sum + 1;
            }
            return sum;
        }, 0);
        if ((this.landingPage.originalTranslation as any).selected) {
            numberOfSelected++;
        }
        return numberOfSelected;
    }

    get affectedTranslations() {
        const affectedVersions: TranslationModel[] = [];
        if (this.type === 'publish') {
            if (this.landingPage.originalTranslation.publishState <= PublishState.Unpublished || this.landingPage.originalTranslation.publishState === PublishState.PublishedWithPendingUpdates) {
                affectedVersions.push(this.landingPage.originalTranslation);
            }
            for (const t of this.landingPage.translations) {
                if (t.publishState <= PublishState.Unpublished || t.publishState === PublishState.PublishedWithPendingUpdates) {
                    affectedVersions.push(t);
                }
            }
        }
        else {
            if (this.landingPage.originalTranslation.publishState >= PublishState.Published) {
                affectedVersions.push(this.landingPage.originalTranslation);
            }
            for (const t of this.landingPage.translations) {
                if (t.publishState >= PublishState.Published) {
                    affectedVersions.push(t);
                }
            }
        }
        return affectedVersions;
    }

    public cancel(): void {
        let response = new BFDialogResponse<Date>();
        response.cancel = true;

        this.callback(response);
    }

    public publish(): void {
        this.loading = true;

        // add selected translations
        let translations: TranslationModel[] = [];
        if ((this.landingPage.originalTranslation as any).selected)
            translations.push(this.landingPage.originalTranslation);
        (this.landingPage.translations || []).forEach((t: any) => {
            if (t.selected) translations.push(t);
        });

        this.publishingText = `0 of ${translations.length} versions published`;

        let dialogResponse = new BFDialogResponse<Date>();
        const callback = (queueLeft: number, queueTotal: number, errorCount: number) => {
            this.publishingText =
                `${queueLeft} of ${queueTotal} versions published. ${errorCount} versions failed.`
        };

        this.publishService
            .publish(this.landingPage, translations, callback, callback)
            .then((response: any) => {
                this.handleCompletedRequest(response, translations);
                this.callback(dialogResponse);
            })
            .catch((response) => {
                this.handleCompletedRequest(response, translations);
            });
    }

    public unpublish() {
        this.loading = true;

        let translations: TranslationModel[] = [];
        if ((this.landingPage.originalTranslation as any).selected)
            translations.push(this.landingPage.originalTranslation);
        (this.landingPage.translations || []).forEach((t: any) => {
            if (t.selected) translations.push(t);
        });

        this.publishingText = 'Unpublishing landing page...';

        var dialogResponse = new BFDialogResponse<Date>();
        const callback = (queueLeft: number, queueTotal: number, errorCount: number) => {
            this.publishingText =
                `${queueLeft} of ${queueTotal} versions unpublished. ${errorCount} versions failed.`
        };
        this.publishService
            .unpublish(this.landingPage, translations, callback, callback)
            .then((response: any) => {
                this.handleCompletedRequest(response, translations);
                this.callback(dialogResponse);
            })
            .catch((response) => {
                this.handleCompletedRequest(response, translations);
            });
    }

    private readonly handleCompletedRequest = async (
        response: PublishProgressResponse,
        processedTranslations: TranslationModel[]): Promise<void> => {
        const failed: string[] = [];
        for (const index in response.failedTranslations) {
            const translation = processedTranslations.find(t => t.id === response.failedTranslations[index].translationId);
            failed.push(`'${translation.name}'`); // - ${response.failedTranslations[index].error}
        }

        if (failed.length) {
            const msg = `Failed to ${this.type} ${failed.join(' | ')}`;
            this.notificationService.show(msg, "error", "top", undefined, 'alert');
        }
        else {
            let successfuls: string[] = [];
            response.successfulTranslationIds.forEach((id) => {
                const translation = processedTranslations.find(t => t.id === id);
                successfuls.push(translation.name);
            });

            if (successfuls.length) {
                const msg = `${successfuls.join(' | ')} have been ${this.type}ed`;
                this.notificationService.show(msg, "success", "top", undefined, 'finished');
            }
        }

        this.landingPage = await this.landingPageService.get();

        this.callback(this.landingPage);
    };

    private toggleSelect(): void {
        this.allSelected = !this.allSelected;

        (this.landingPage.originalTranslation as any).selected = this.allSelected;
        (this.landingPage.translations || []).forEach((t: any) => {
            t.selected = this.allSelected;
        });
    }

    private emptySelection(): void {
        this.allSelected = false;
        (this.landingPage.originalTranslation as any).selected = false;
        (this.landingPage.translations || []).forEach((t: any) => {
            t.selected = false;
        });
    }

    private isNullOrEmpty(text: string): boolean {

        // uses == instead of === on purpose, this check should be as sensitive as possible
        return text == null || text == '';
    }

    private validate(): boolean {
        let anySlugIsEmpty = this.isNullOrEmpty(this.landingPage.originalTranslation.publishSlug);

        if (this.landingPage.translations && !anySlugIsEmpty) {
            this.landingPage.translations.forEach((t) => {
                if (this.isNullOrEmpty(t.publishSlug)) anySlugIsEmpty = true;
            });
        }

        return !anySlugIsEmpty;
    }

    private getUrl(translation: TranslationModel): string {
        let url = this.publishService.getCname(
            translation.localizationId, this.landingPage.accountSlug, this.landingPage.brandId, this.cnames);

        return (`${url}${translation.publishSlug}/index.html`).toLowerCase();
    }

    ngOnDestroy() { }
}
