import { Component, OnChanges, Input, Output, SimpleChanges, EventEmitter, ViewChild, ElementRef, ComponentRef} from '@angular/core';
import { BFMaterial } from '../../bfMaterial';
import { BFServerResponse } from '../../models/index';
import { BFTooltipComponent } from '../tooltip/bfTooltip.component';
import { BFTooltipContainer } from '../../containers/index';

import 'jquery';
import { NgClass, NgIf } from '@angular/common';

export class BFButtonConfig {
    public icon: string;
    public size: string;
    public type: string;

    constructor(config: Partial<BFButtonConfig> = {}) {
        Object.assign(this, config);
    }
}

@Component({
    selector: 'bfButton',
    templateUrl: './bfButton.component.html',
    standalone: true,
    styleUrls: ['./bfButton.component.scss'],
    imports: [NgClass, NgIf]
})
export class BFButtonComponent implements OnChanges {

    @Input('type') public type: string;
    @Input('size') public size: string;
    @Input('disabled') public disabled: string;
    @Input('config') public config: BFButtonConfig;

    @Input('bfButton') public bfButton: BFButtonConfig;
    @ViewChild('button', { static: true }) public elementRef: ElementRef;

    public loadingState: string = 'initial';
    public loadingText: string;
    public loadingDoneText: string;
    public tooltipComponentRef: ComponentRef<BFTooltipComponent>;
    public tooltipTimeout: any;

    constructor(
        public tooltipContainer: BFTooltipContainer,
        public rootElementRef: ElementRef,
        public bannerFlowMaterial: BFMaterial
    ) {
            this.config = new BFButtonConfig();
    }

    public ngOnChanges(changes: any):void{
        if (changes.disabled != null) {
            this.rootElementRef.nativeElement.classList.toggle('bfButton--disabled', changes.disabled.currentValue ? true : false);
        }
    }

    public handlePromise(promise: Promise<BFServerResponse<any>>,
        loadingText: string = '',
        loadingDoneText: string = '',
        tooltipDoneText: string = '',
        delay: number = 2500): void {
        let self = this;

        if (this.tooltipTimeout) {
            window.clearTimeout(this.tooltipTimeout);
        }

        this.setLoadingState('loading');
        this.loadingText = loadingText;
        this.loadingDoneText = loadingDoneText;

        jQuery(this.elementRef.nativeElement).toggleClass('bfTooltip--tooltipOpen', true);

        promise.then((serverResponse: BFServerResponse<any>) => {
            this.setLoadingState('done');

            // Remove all tooltips
            self.tooltipContainer.clear();

            if (tooltipDoneText) {
                self.tooltipComponentRef = self.tooltipContainer.attach<BFTooltipComponent>(BFTooltipComponent, self.bannerFlowMaterial.rootViewContainerRef);
                self.tooltipComponentRef.instance.initiate(self.elementRef, tooltipDoneText, 'top', 0, undefined, 'wiggle');

                this.tooltipTimeout = setTimeout(function () {
                    self.tooltipContainer.detach(self.tooltipComponentRef);
                    jQuery(self.elementRef.nativeElement).toggleClass('bfTooltip--tooltipOpen', false);
                }, delay);
            } else {
                jQuery(self.elementRef.nativeElement).toggleClass('bfTooltip--tooltipOpen', false);
            }

        }).catch((serverResponse: BFServerResponse<any>) => {
            this.setLoadingState('initial');
            this.loadingText = '';
            this.loadingDoneText = '';

            // Remove all tooltips
            self.tooltipContainer.clear();

            self.tooltipComponentRef = self.tooltipContainer.attach<BFTooltipComponent>(BFTooltipComponent, self.bannerFlowMaterial.rootViewContainerRef);
            self.tooltipComponentRef.instance.initiate(self.elementRef, serverResponse.errorMessage, 'top', 0, 'red', 'wiggle');

            this.tooltipTimeout = setTimeout(function () {
                self.tooltipContainer.detach(self.tooltipComponentRef);
                jQuery(self.elementRef.nativeElement).toggleClass('bfTooltip--tooltipOpen', false);
            }, 2500)
        });
    }

    private setLoadingState(state: string):void{
        this.loadingState = state;

        // Toggle loading state
        this.rootElementRef.nativeElement.classList.toggle('bfButton--loading', this.loadingState === 'loading');
    }

}
