import { Injectable } from '@angular/core';
import { BFMaterial, BFOverlayContainer, BFDialogResponse } from '../../../../libs/material/index';
import { PublishOverlayComponent } from './publishOverlay.component';
import { LandingPageModel } from '../../models/landingPage.model';
import { TranslationModel } from '../../models/translation.model';

@Injectable({ providedIn: 'root' })
export class PublishOverlayService {
    constructor(private overlayContainer: BFOverlayContainer,
        private bfMaterial: BFMaterial) {

    }

    public show(landingPage: LandingPageModel, cnames: any, translations: TranslationModel[], type: 'publish' | 'unpublish'): Promise<BFDialogResponse<Date>> {
        var publishDialog = this.overlayContainer.attach(PublishOverlayComponent, this.bfMaterial.rootViewContainerRef);

        var promise = new Promise<BFDialogResponse<Date>>((resolve) => {
            publishDialog.instance.initiate(landingPage, cnames, translations, type).then((response) => {
                this.overlayContainer.detach(publishDialog);
            });
        });

        return promise;
    }
}
