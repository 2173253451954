<bfDialog (onClose)="cancel()">
    <ui-loader *ngIf="loading"></ui-loader>
    <div class="uploadDialog">
        <ui-loader *ngIf="loading"></ui-loader>
        <h3>{{ title }}</h3>
        <div class="uploadDialog__files"
             *ngIf="selectedFiles.length">
            <div class="uploadDialog__file"
                 *ngFor="let file of selectedFiles">
                {{ file.name | ellipsisMiddle:50 }} | {{ file.size | bfBytes }}
                <i class="bf-icon bf-icon-trash"
                   (click)="removeFile(file)"></i>
            </div>
        </div>
        <div class="uploadDialog__addFiles"
             *ngIf="multiple || (!multiple && selectedFiles.length === 0)">
            {{ multiple ? 'Add files...' : 'Select file...' }}
            <input #fileInput
                   class="uploadDialog__addFilesInput"
                   (change)="selectFiles($event)"
                   [attr.multiple]="multiple"
                   [attr.accept]="allowedFiles"
                   type="file"/>
        </div>
        <div class="bfDialog__buttons">
            <bfButton (click)="cancel()">
                Cancel
            </bfButton>
            <bfButton (click)="migrate()"
                      *ngIf="zipChosen">
                Migrate old Landing page
            </bfButton>
            <bfButton (click)="upload()"
                      [disabled]="selectedFiles.length === 0"
                    type="primary">
                Upload
            </bfButton>
        </div>
    </div>
</bfDialog>
