import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BFMaterial } from './bfMaterial';
import { BFConfirmDialogService } from './components/confirmDialog/bfConfirmDialog.service';
import { BFNotificationService } from './services/notification/bfNotification.service';
import { BFNotificationComponent } from './services/notification/bfNotification.component';

// Components
import {
    BFButtonComponent,
    BFConfirmDialogComponent,
    BFDatePickerButton,
    BFDatePickerComponent,
    BFDialogComponent,
    BFOverlayComponent,
    BFPopoverComponent,
    BFSelectComponent,
    BFSelectDirective,
    BFSelectListComponent,
    BFTooltipComponent,
    BFTooltipDirective,
    BFTagInputComponent,
    BFSearchComponent
} from './components/index';

// Directives
import {
    BFContextMenuDirective,
    BFInlineEditDirective,
    BFMenuComponent,
    BFMenuDirective,
    BFMenuItem,
    BFMenuConfig,
    BFMenuConfigOptions
} from './directives/index';

// Containers
import {
    BFComponentContainer,
    BFDialogContainer,
    BFOverlayContainer,
    BFTooltipContainer
} from './containers/index';

// Services
import {
    BFPositionService,
    BFUtilsService
} from './services/index';

// Pipes
import {
    BFAgoPipe,
    BFBytesPipe,
    BFCapitalizePipe,
    BFEllipsisPipe,
    BFMapPropertyPipe,
    BFOrderByPipe,
    BFSecondsPipe,
    BFToSentencePipe
} from './pipes/index';
import { FormsModule } from '@angular/forms';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        // Components
        BFButtonComponent,
        BFConfirmDialogComponent,
        BFConfirmDialogComponent,
        BFDatePickerButton,
        BFDatePickerComponent,
        BFDialogComponent,
        BFMenuComponent,
        BFNotificationComponent,
        BFNotificationComponent,
        BFOverlayComponent,
        BFPopoverComponent,
        BFSelectComponent,
        BFSelectListComponent,
        BFConfirmDialogComponent,
        BFNotificationComponent,
        BFConfirmDialogComponent,
        BFNotificationComponent,
        BFDatePickerComponent,
        BFDatePickerButton,
        BFTagInputComponent,
        BFTooltipComponent,
        BFSearchComponent,
        // Directives
        BFTooltipDirective,
        BFInlineEditDirective,
        BFInlineEditDirective,
        BFSelectDirective,
        BFMenuDirective,
        BFInlineEditDirective,
        BFInlineEditDirective,
        BFContextMenuDirective,
        // Pipes
        BFAgoPipe,
        BFBytesPipe,
        BFCapitalizePipe,
        BFEllipsisPipe,
        BFMapPropertyPipe,
        BFOrderByPipe,
        BFSecondsPipe,
        BFToSentencePipe
    ],
    exports: [
        // Components
        BFButtonComponent,
        BFConfirmDialogComponent,
        BFDatePickerButton,
        BFDatePickerComponent,
        BFDialogComponent,
        BFNotificationComponent,
        BFOverlayComponent,
        BFPopoverComponent,
        BFSelectComponent,
        BFNotificationComponent,
        BFDatePickerComponent,
        BFDatePickerButton,
        BFSelectListComponent,
        BFTagInputComponent,
        BFTooltipComponent,
        BFSearchComponent,
        // Directives
        BFInlineEditDirective,
        BFTooltipDirective,
        BFSelectDirective,
        BFMenuDirective,
        BFContextMenuDirective,
        // Pipes
        BFAgoPipe,
        BFBytesPipe,
        BFCapitalizePipe,
        BFEllipsisPipe,
        BFMapPropertyPipe,
        BFOrderByPipe,
        BFSecondsPipe,
        BFToSentencePipe
    ],
    providers: [
        BFComponentContainer,
        BFConfirmDialogService,
        BFDialogContainer,
        BFMaterial,
        BFNotificationService,
        BFOverlayContainer,
        BFPositionService,
        BFTooltipContainer,
        BFUtilsService,
        // Pipes
        BFAgoPipe,
        BFBytesPipe,
        BFCapitalizePipe,
        BFEllipsisPipe,
        BFMapPropertyPipe,
        BFOrderByPipe,
        BFSecondsPipe,
        BFToSentencePipe,
        BFConfirmDialogComponent,
        BFMenuComponent,
        BFNotificationComponent,
        BFPopoverComponent,
        BFSelectListComponent,
        BFTooltipComponent
    ]
})
export class BFMaterialModule { }
