<div class="translation">
    <div class="translation__top">
        <div class="header">
            <div class="header__section header__section--left">
                <div class="backButton"
                     [routerLink]="backLink">
                    <i class="bf-icon bf-icon-arrow-left-a"></i>&nbsp;&nbsp;
                    Back to translation
                </div>
            </div>
            <div class="header__section header__section--center">
                <div class="currentTranslation"
                     *ngIf="textService.currentTranslation">
                    <span>Translating&nbsp;</span>
                    <ui-flag size="tiny" [culture]="textService.currentTranslation.culture"></ui-flag>&nbsp;
                    {{ textService.currentTranslation.name | ellipsisMiddle: 20 }}
                </div>
            </div>
            <div class="header__section header__section--right">
                <bfButton type="success"
                          size="small"
                          *ngIf="!(landingPageService.dirty || isSaving)"
                          (click)="complete()">
                          <i class="bf-icon bf-icon-checkmark"></i>
                          Complete translation
                </bfButton>
                <bfButton #saveButton
                          type="primary"
                          size="small"
                          *ngIf="landingPageService.dirty || isSaving"
                          (click)="save()">
                          <i class="bf-icon bf-icon-save"></i>
                          Save
                </bfButton>
            </div>
        </div>
    </div>
    <div class="translation__page">
        <router-outlet></router-outlet>
    </div>
</div>
