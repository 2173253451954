import { Component, ElementRef, OnInit, OnDestroy, Renderer2, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { BFPositionService, BFUtilsService } from '../../services/index';
import { BFConstants } from '../../models/index';
import { BFOverlayContainer } from '../../containers/bfOverlay.container'

import 'jquery';
import { NgIf } from '@angular/common';

@Component({
    selector: 'bfOverlay',
    templateUrl: './bfOverlay.component.html',
    styleUrls: ['./bfOverlay.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class BFOverlayComponent implements OnInit, OnDestroy {
    @Output() public onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public theme: string;
    @Input() public closable: boolean = true;

    constructor(private overlayContainer: BFOverlayContainer) { }

    ngOnInit(): void {
        //If overlaycontainer only contains one instance of a overlay
        //set body overflow css setting
        if (this.overlayContainer.getCount() === 1) {
            BFUtilsService.toggleBodyOverflow(true);
        }
    }

    ngOnDestroy(): void {
        this.resetBodyOverflow();
    }

    public close() {
        this.resetBodyOverflow();
        this.onClose.emit(true);
    }

    private resetBodyOverflow(): void {
        //If overlaycontainer only contains one instance of a overlay
        //remove body overflow css settings

        if (this.overlayContainer.getCount() < 2) {
            BFUtilsService.toggleBodyOverflow(false);
        }
    }

    @HostListener('document:keydown', ['$event'])
    private handleKeyboardEvent(event: KeyboardEvent) {
        switch (event.keyCode) {
            case BFConstants.KEYS.ESCAPE:
                if (this.closable) {
                    this.close();
                }
                break;
        }
    }
}
