// libs
import { Injectable } from "@angular/core";
import {
    BFMaterial,
    BFOverlayContainer
} from '../../../../../../libs/material/index';

import { AddTranslationsComponent } from "./addTranslations.component";

@Injectable({ providedIn: 'root' })
export class ManageVersionsOverlayService {

    constructor(private readonly bfMaterial: BFMaterial,
        private readonly overlayContainer: BFOverlayContainer) { }

    public open(): Promise<boolean> {
        let manageVersionsOverlay = this.overlayContainer.attach(
            AddTranslationsComponent, this.bfMaterial.rootViewContainerRef);

        return new Promise<boolean>((resolve: Function) => {
            manageVersionsOverlay.instance.activate().then((result: boolean) => {
                this.overlayContainer.detach(manageVersionsOverlay);
                resolve(result);
            });
        });
    }
}
