<div class="footer">
    
    <div class="footer__section footer__section--left">
        <bfButton (click)="exit()"
                  bfTooltip="To Bannerflow"
                  bfTooltipPlacement="top"
                  size="small">
            <i class="bf-icon bf-icon-exit"></i>
            Exit
        </bfButton>
    </div>

    <div class="footer__section footer__section--center">
        <div class="footer__menu">
            <div class="footer__menuItem"  
                 [routerLink]="['./design']"
                 [ngClass]="{'footer__menuItem--active': designRoute}"
                 bfTooltip="Design view"
                 bfTooltipPlacement="top">
                <i class="bf-icon bf-icon-view-design"></i>
                Design
            </div>
            <div [routerLink]="['./code']" 
                 class="footer__menuItem"
                 routerLinkActive="footer__menuItem--active"
                 bfTooltip="Code view"
                 bfTooltipPlacement="top">
                <i class="bf-icon bf-icon-view-code"></i>
                Code
            </div>
        </div>
    </div>

    <div class="footer__section footer__section--right">
        <bfButton (click)="showcase()"
                  bfTooltip="Showcase version"
                  bfTooltipPlacement="top"
                  size="small">
            <i class="bf-icon bf-icon-preview"></i>
            Showcase
        </bfButton>
    </div>
</div>