// libs
import { Component, ViewChild } from '@angular/core';

import {
  BFButtonComponent,
  BFServerResponse,
  BFConfirmDialogService,
  BFNotificationService
} from '../../../../libs/material/index';
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from "@angular/router";
import {NgIf} from "@angular/common";
import {LandingPageService} from "shared/services/landingPage.service";
import {TextService} from "shared/services/text.service";
import {EllipsisMiddlePipe} from "shared/pipes/ellipsisMiddle.pipe";
import {UserService} from "shared/services/user.service";
import {ApiService} from "shared/services/api.service";
import {HotkeysService} from "shared/services/hotkeys.service";
import {LandingPageModel} from "shared/models/landingPage.model";
import {TranslationModel} from "shared/models/translation.model";
import {UIModule} from "@bannerflow/ui";

@Component({
    selector: 'translatorView',
    styleUrls: ['translation.view.component.scss'],
    templateUrl: 'translation.view.component.html',
    standalone: true,
  imports: [RouterLink, NgIf, BFButtonComponent, RouterOutlet, EllipsisMiddlePipe, UIModule]
})
export class TranslationViewComponent {
    @ViewChild('saveButton', { static: true }) saveButton: BFButtonComponent;

    public backLink: string;
    public landingPageId: string;
    public translatorId: string;
    public isSaving = false;

    constructor(public landingPageService: LandingPageService,
        public textService: TextService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly userService: UserService,
        private readonly apiService: ApiService,
        private readonly confirmDialogService: BFConfirmDialogService,
        private readonly router: Router,
        private readonly notificationService: BFNotificationService,
        private readonly hotkeysService: HotkeysService) {


        this.hotkeysService.bindKey(['command+s', 'ctrl+s'], (event) => {
            this.save();
        });

        this.activatedRoute.params.subscribe((params: any) => {
            this.userService.get()
                .then((user) => {
                    if (params.translationId) {
                        this.landingPageService.get().then((landingPage: LandingPageModel) => {
                            this.landingPageId = landingPage.id;
                            this.backLink = `/translator/${params.translatorId}`;
                            this.translatorId = params.translatorId;
                            let translation: TranslationModel = landingPage.translations.find((t: TranslationModel) => {
                                return t.id === params.translationId;
                            });
                            this.textService.setCurrentTranslation(translation);
                        });
                    }
                });
        });
    }

    public complete(): void {
        const confirmHandler = () => {
            let action =
                `complete?translationId=${this.textService.currentTranslation.id}&translatorId=${this.translatorId}&landingPageId=${this.landingPageId}`;

            return this.apiService.get('externalTranslation', action)
                .then(() => {
                    this.router.navigate(['/translator', this.translatorId]);
                });
        };

        // todo: author the text messages
        this.confirmDialogService.show(
            'Complete translation',
            'Happy with your work? Confirm it\'s complete, and then the landing page can launch internationally! Nice job.',
            'Yes',
            'No',
            confirmHandler);
    }

    public save(): void {
        if (!this.landingPageService.dirty) {
            return;
        }

        this.isSaving = true;

        let data = { texts: [], resourceTranslations: [] };

        if (this.textService.currentTranslation.texts) {
            this.textService.currentTranslation.texts.forEach((t) => {
                data.texts.push({ key: t.key, value: t.value });
            });
        }

        if (this.textService.currentTranslation.resourceTranslations) {
            this.textService.currentTranslation.resourceTranslations.forEach((t) => {
                data.resourceTranslations.push({ resourceId: t.resourceId, originalResourceId: t.originalResourceId });
            });
        }

        this.landingPageService.toggleDirty(false);

        const action = `save?landingPageId=${this.landingPageId}&translationId=${this.textService.currentTranslation.id}`;
        this.saveButton.handlePromise(
            this.apiService.post('externalTranslation', action, data)
                .then(
                    (BFServerResponse: any) => {
                        this.isSaving = false;
                        this.notificationService.show(`You translation is saved`, 'success', 'top', undefined, 'finished');
                        return new BFServerResponse();
                    }
                )
                .catch(() => {
                    this.isSaving = false;
                    this.landingPageService.toggleDirty(true);
                }),
            'Saving...');
    }
}
