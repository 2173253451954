<bfOverlay (onClose)="cancel()"
           theme="gray">
    <ui-loader *ngIf="loading"></ui-loader>
    <div class="publishOverlayStatus"
        *ngIf="loading">
        {{ publishingText }}
        <div class="publishOverlayStatus__error">
            {{ publishingErrorText }}
        </div>
    </div>
    <div class="publishOverlay"
         *ngIf="!loading">
        <div class="publishOverlay__wrapper">
            <div class="publishOverlay__header" *ngIf="type === 'publish'">
                <div class="publishOverlay__headerTitle">
                    Publish landing page
                </div>
                <div class="publishOverlay__headerText">
                    You will publish your landing page in {{ numberOfSelectedVersions }} text versions.
                </div>
                <div class="publishOverlay__headerButtons">
                    <bfButton (click)="cancel()" id="interaction-cancel-publish-landingpage-btn">Cancel</bfButton>
                    <bfButton (click)="publish()"
                              id="interaction-publish-landingpage-btn"
                              type="success">Publish</bfButton>
                </div>
            </div>
            <div class="publishOverlay__header" *ngIf="type === 'unpublish'">
                <div class="publishOverlay__headerTitle">
                    Unpublish landing page
                </div>
                <div class="publishOverlay__headerText">
                    You will unpublish your landing page in {{ numberOfSelectedVersions }} text versions.
                </div>
                <div class="publishOverlay__headerButtons">
                    <bfButton (click)="cancel()" id="interaction-cancel-unpublish-landingpage-btn">Cancel</bfButton>
                    <bfButton (click)="unpublish()"
                              id="interaction-unpublish-landingpage-btn"
                              type="success">Unpublish</bfButton>
                </div>
            </div>
            <div class="publishOverlay__content">
                <div class="seperator">
                    <div class="seperator__text">Versions</div>
                </div>
                <table class="bfBlockTable">
                    <thead>
                        <tr>
                            <th><ui-checkbox [selected]="allSelected"
                                            (click)="toggleSelect()"></ui-checkbox></th>
                            <th>Language</th>
                            <th>Publish URL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let translation of affectedTranslations">
                            <td>
                                <ui-checkbox [(selected)]="translation.selected"></ui-checkbox>
                            </td>
                            <td>
                                <ui-flag size="tiny"  [culture]="translation.culture"></ui-flag>
                                {{ translation.name | ellipsisMiddle: 20 }}
                            </td>
                            <td class="bfBlockTable__subText">
                                {{ getUrl(translation) | ellipsisMiddle:70}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</bfOverlay>
