import { BFDialogResponse } from './../../models/ui/bfDialogResponse.model';
import { Component } from '@angular/core';
import { BFDialogComponent } from '../dialog/bfDialog.component';
import { NgIf } from '@angular/common';
import { BFButtonComponent } from '../button/bfButton.component';
import {UIModule} from "@bannerflow/ui";

@Component({
    selector: 'bfConfirmDialog',
    templateUrl: './bfConfirmDialog.component.html',
    standalone: true,
  imports: [BFDialogComponent, NgIf, BFButtonComponent, UIModule]
})
export class BFConfirmDialogComponent {
    public loading: boolean;
    public text: string;
    public subText: string;
    public confirmText: string;
    public cancelText: string;

    private callback: Function;
    private confirmAction: Function | undefined;

    public initiate(
        text: string,
        subText: string,
        confirmText: string,
        cancelText: string,
        confirmAction: Function | undefined
    ): Promise<BFDialogResponse<boolean>> {
        this.text = text;
        this.subText = subText;
        this.confirmText = confirmText;
        this.cancelText = cancelText;
        this.confirmAction = confirmAction;

        const promise = new Promise<BFDialogResponse<boolean>>((resolve) => {
            this.callback = resolve;
        });

        return promise;
    }

    public cancel(): void {
        const response = new BFDialogResponse<boolean>();
        response.cancel = true;

        this.callback(response);
    }

    public confirm(): void {
        const response = new BFDialogResponse<boolean>();
        if (this.confirmAction) {
            this.loading = true;
            this.confirmAction().then(() => {
                this.callback(response);
            });
        }
        else {
            this.callback(response);
        }

    }

}
