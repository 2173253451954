<bfDialog (onClose)="cancel()">
    <ui-loader *ngIf="loading"></ui-loader>
    <h3>{{ text }}</h3>
    <p>{{ subText }}</p>
    <div class="bfDialog__buttons">
        <bfButton (click)="cancel()">
            {{ cancelText }}
        </bfButton>
        <bfButton (click)="confirm()" type="primary">
            {{ confirmText }}
        </bfButton>
    </div>
</bfDialog>
