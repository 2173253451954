<div #button
     class="bfButton bfButton--{{ type || config.type }} bfButton--{{ size || config.size }}"
    [ngClass]="{ 'bfButton--loading' : loadingState === 'loading', 'bfButton--active' : loadingState === 'loading', 'bfButton--icon' : config.icon }">
    <span class="bfButton__loading"
          *ngIf="loadingState === 'loading'  && loadingText">
        <span class="bfBanner__loader"></span>
        <span [innerHTML]="loadingText"></span>
    </span>
    <span class="bfButton__loadingDone"
          *ngIf="loadingState === 'done' && loadingDoneText">
        <span [innerHTML]="loadingDoneText"></span>
    </span>
    <span class="bfButton__content" *ngIf="loadingState === 'initial' 
                                        || (loadingState === 'loading' && !loadingText)
                                        || (loadingState === 'done' && !loadingDoneText)">
        
        <i class="bfButton__icon bf-icon bf-icon-{{ config.icon }} bf-icon-fw bf-icon-lg"
           *ngIf="config.icon"></i>
        <ng-content *ngIf="!config.icon"></ng-content>
    </span>
</div>